// import GLightbox from 'glightbox'
export class HmwLightbox {
    #selector: string
    #attribute: string

    constructor(selector: string, attribute: string) {
        this.#selector = selector
        this.#attribute = attribute

        this.#init()
    }
    
    #init() {
        if (this.#selector !== undefined) {
            let galleries = document.querySelectorAll(this.#selector);
            galleries.forEach( (gallery) => {
                if (gallery.getAttribute(this.#attribute) !== null) {
                    let galleryID = gallery.getAttribute(this.#attribute);
                    new (window as { [key: string]: any })['GLightbox']({
                        selector: '.hmw-lightbox-' + galleryID,
                        touchNavigation: true,
                    });
                }
            });
        }
    }
}